import React, { useState } from 'react';
import { Navigate } from "react-router-dom";
import Header from './Header';
import Sidebar from './Sidebar';

function MainSection(props) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(localStorage.getItem('sidebar_open') ? true : false);
  const [isSidebarMin, setIsSidebarMin] = useState(localStorage.getItem('sidebar_mini') ? true : false);

  function handleSidebarClick() {
    isSidebarOpen ? localStorage.removeItem('sidebar_open') : localStorage.setItem('sidebar_open', 'open');
    setIsSidebarOpen(!isSidebarOpen);
  };

  function handleMiniClick() {
    isSidebarMin ? localStorage.removeItem('sidebar_mini') : localStorage.setItem('sidebar_mini', 'mini');
    setIsSidebarMin(!isSidebarMin);
  };

  function getClasesForMain(isSidebarOpen, isSidebarMin) {
    let classList = 'section-main';

    if (isSidebarOpen)
      classList = `section-main ${isSidebarMin ? 'section-main_mini' : ''}`;

    return classList;
  }

  return (
    props.loggedIn === true ? (
      <>
        <Header handleSidebarClick={handleSidebarClick} onSignOut={props.onSignOut} />
        <Sidebar isOpen={isSidebarOpen} isMini={isSidebarMin} handleMiniClick={handleMiniClick} />
        <main className={getClasesForMain(isSidebarOpen, isSidebarMin)}>
          {props.component}
        </main>
      </>
    ) : (
      <Navigate to='/signin' />
    )
  );
}

export default MainSection;
