import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Auth from '../../utils/Auth';
import Login from './Login';
import Register from './Register';
import Recovery from './Recovery';
import logo from '../../images/yashaLogo.jpg';

function Authorization(props) {
  const location = useLocation();
  const navigate = useNavigate();

  if (props.loggedIn === true)
    navigate('/request');


  const onReg = (login, email, password) => {
    return Auth.register(login, email, password)
      .then((res) => {
        if (!res) throw new Error("Что-то пошло не так");

        return res;
      });
  };

  const onLog = (login, password) => {
    return Auth.authorize(login, password)
      .then((res) => {
        console.log(res);

        localStorage.setItem("jwt", res.token);
        props.auth(res.token);
      })
  };

  function getForm(location) {
    switch (location) {
      case '/signin':
        return <Login onLog={onLog} />;
      case '/recovery':
        return <Recovery />;
      case '/signup':
        return <Register onReg={onReg} />;
      default:
        return <></>
    }
  }

  function getFormName(location) {
    if (location === '/recovery') {
      return <fieldset className='auth__selecters auth__selecters_one'>
        <input className='auth__selecter' checked={location === '/recovery'} type='radio' id='recovery' onChange={() => { }} />
        <label className='auth__selecter-label auth__selecter-label_one' htmlFor='recovery'>{location === '/signup' ? 'Регистрация' : 'Восстановление пароля'}</label>
      </fieldset>
    } else {
      return <fieldset className='auth__selecters'>
        <input className='auth__selecter'
          checked={location === '/signin'} type='radio' id='login' disabled={location === '/signin'} onChange={() => navigate('/signin')} />
        <label className='auth__selecter-label' htmlFor='login'>Войти</label>
        <input className='auth__selecter auth__selecter_right' type='radio' name='register'
          checked={location === '/signup'} disabled={location === '/signup'} onChange={() => navigate('/signup')} />
        <label className='auth__selecter-label' htmlFor='register'>Регистрация</label>
      </fieldset>
    }
  }

  return (
    <div className="wrapp">
      <div className="auth">
        <div className="auth__wrapp_logo">
          <img className="auth__logo" src={logo} alt='Logotype' />
        </div>
        <div className="auth__container">
          {getFormName(location.pathname)}
          {getForm(location.pathname)}
        </div>
      </div>
    </div >
  );
};

export default Authorization;