import React from 'react';
import { YMaps } from '@pbe/react-yandex-maps';
import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import * as Auth from '../utils/Auth';
import Authorization from './AuthForm/Authorization';
import { CurrentUserContext, CurrentUser } from '../contexts/CurrentUserContext';
import { RoleProvider } from '../contexts/RoleContext';
import createApi from '../utils/Api';
import Profile from './Profile';
import Company from './Company';
import Admin from './Admin';
import MainSection from './MainSection';
import Request from './Request';
import FormMap from './FormMap';

function App() {
  const [jwt, setJwt] = useState(localStorage.getItem('jwt'));
  const [loggedIn, setLoggedIn] = useState(jwt ? true : false);
  const [user, setUser] = useState(CurrentUser);
  const [api, setApi] = useState(null);

  const navigate = useNavigate();

  const auth = (jwt) => {
    setLoggedIn(true);
    setApi(createApi(jwt));

    Auth.getContent(jwt)
      .then((res) => {
        setUser(res);
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 401) {
          localStorage.removeItem('jwt');
          setJwt(null);
        }
      });
  };

  const onSignOut = () => {
    localStorage.removeItem('jwt');
    setJwt(null);
    setApi(null);
    setLoggedIn(false)
    setUser(CurrentUser);
    navigate('/signin');
  };

  useEffect(() => {
    if (jwt)
      auth(jwt);
  }, [jwt]);

  return (
    <div className="App">
      <YMaps>
        <CurrentUserContext.Provider value={user}>
          <RoleProvider>
            <Routes>
              <Route path="/form/map" element={<FormMap />} />

              <Route path="/signin" element={<Authorization auth={auth} loggedIn={loggedIn} />} />
              <Route path="/signup" element={<Authorization loggedIn={loggedIn} />} />
              <Route path="/recovery" element={<Authorization loggedIn={loggedIn} />} />
              <Route path="/profile" element={<MainSection component={<Profile Api={api} />} loggedIn={loggedIn} onSignOut={onSignOut} />} />
              <Route path="/company" element={<MainSection component={<Company Api={api} />} loggedIn={loggedIn} onSignOut={onSignOut} />} />

              <Route path="/request" element={<MainSection component={<Request Api={api} />} loggedIn={loggedIn} onSignOut={onSignOut} />} />

              <Route path="/admin" element={<MainSection component={<Admin Api={api} />} loggedIn={loggedIn} onSignOut={onSignOut} />} />

              <Route path="*" element={<Navigate to="./profile" />}></Route>
            </Routes>
          </RoleProvider>
        </CurrentUserContext.Provider>
      </YMaps>
    </div>
  );
}

export default App;
