import React, { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import useFormWithValidation from '../utils/Validator';

function Profile(props) {
  const user = useContext(CurrentUserContext).profile;
  const { values, handleChange, isValid, errors, resetForm, setValues } = useFormWithValidation();

  function handleSubmit(e) {
    e.preventDefault();

    props.Api.updateProfile(values)
      .then(res => {
        window.location.reload();
      })
      .catch(err => {
        console.log(err)
      });
  };

  useEffect(() => {
    setValues(user);
  }, [props.Api, user]);

  function inputTemplate(id, type, disabled = false, name, placeholder, isRequired = false, validMessage = 'Поле имеет ошибочный формат', minLength = 2, maxLength = 50, pattern = '.*', onChange = handleChange) {
    return (
      <div className='profile__input-box'>
        <label className='profile__label' htmlFor={id}>{name}</label>
        <input className={`profile__input ${errors[id] ? 'profile__input_theme_invalid' : ''}  ${errors[id] === '' ? 'profile__input_theme_valid' : ''}`}
          type={type}
          id={id} name={id}
          placeholder={placeholder}
          minLength={minLength} maxLength={maxLength}
          value={values[id] || ''}
          onChange={onChange} required={isRequired}
          disabled={!disabled}
          pattern={pattern} />
        <label className={`profile__label_valid`} style={{ 'display': errors[id] ? 'block' : 'none' }} htmlFor={id}>{validMessage}</label>
      </div>
    )
  }

  let confirmMessage = `Я${', ' + values.surname} ${values.name} ${values.patronymic} подтверждаю своё согласие на передачу информации в электронной форме (в том числе персональных данных) по открытым каналам связи сети Интернет, а также на обработку персональных данных.`

  return (
    <div className='profile' onSubmit={handleSubmit} noValidate>
      <h2 className='profile__tittle'>Заполнить информацию профиля</h2>

      <form className='profile__content' noValidate>
        <fieldset className='profile__item'>
          {inputTemplate('surname', 'text', true, 'Фамилия', 'Введите фамилию', true, 'Поле Фамилия имеет ошибочный формат', 2, 50, '^[А-Яа-яё]+$')}
          {inputTemplate('name', 'text', true, 'Имя', 'Введите имя', true, 'Поле Имя имеет ошибочный формат', 2, 50, '^[А-Яа-яЁё]+')}
          {inputTemplate('patronymic', 'text', true, 'Отчество (если есть)', 'Введите отчество', false, 'Поле Отчество имеет ошибочный формат', 2, 50, '^[А-Яа-яЁё]+')}
        </fieldset>

        <fieldset className='profile__item'>
          {inputTemplate('phone', 'text', true, 'Контактный номер', '+7(812)123-45-67', true, 'Формат +7(812)123-45-67 или 88121234567', 2, 16)}
          {inputTemplate('email_contact', 'email', true, 'Контактный email', 'email@email.ru', true, 'Формат email@email.ru', 2, 256)}
        </fieldset>

        <div className='profile__input-box profile__input-box_them_row' style={{ 'display': values.surname || values.name ? 'flex' : 'none' }}>
          <input type='checkbox' id='accept' name='accept' checked={values.accept === true} onChange={() => setValues({ ...values, accept: !values.accept })} required />
          <label className='profile__label ' htmlFor='accept'>{confirmMessage}</label>
        </div>
        <button id='submit' className='profile__submit' type='submit' disabled={!isValid}>Сохранить</button>
      </form>
    </div>
  );
};

export default Profile;
