import React, { useState, useEffect } from 'react';
import useFormWithValidation from '../utils/Validator';

function ContainerPopup({ component: Component, ...props }) {
  const [navSelect, setNavSelect] = useState(1);
  const { values, handleChange, isValid, resetForm, setValues } = useFormWithValidation('profile__input_theme_invalid', 'profile__input_theme_valid');

  function handleSubmit(e) {
    e.preventDefault();

    if (!isValid)
      return;

    props.Api.updateCompany(values)
      .then(() => {
        props.refreshHandler();
        handleCloseButton();
      })
      .catch((err) => {
        console.error('Ошибка при загрузке файла:', err);
      })
  }

  function handleCloseButton() {
    props.popupHandler(!props.isOpen);
    props.setPopupData({});
    resetForm();
    setNavSelect(1);

    const select = document.querySelector('.popup__nav-item_theme_select');
    if (select)
      select.classList.remove('popup__nav-item_theme_select');
    const nav = document.querySelector('.popup__nav');
    if (nav)
      nav.firstElementChild.classList.add('popup__nav-item_theme_select');
  }

  const handleNavClick = (event) => {
    const select = document.querySelector('.popup__nav-item_theme_select');
    if (select)
      select.classList.remove('popup__nav-item_theme_select');

    event.target.classList.add('popup__nav-item_theme_select');
    setNavSelect(event.target.id);
  };

  function defaultInput(text, id, placeholder, isRequired = false, validMessage = '', minLength = 2, maxLength = 20, type = 'text',) {
    return (
      <div className='profile__input-box'>
        <label className='profile__label' htmlFor={id}>{text}</label>
        <input className='profile__input' type={type} id={id} name={id} placeholder={placeholder} minLength={minLength} maxLength={maxLength}
          value={values[id] || ''} onChange={handleChange} required={isRequired} />
        <label className='profile__label_valid' htmlFor={id}>{validMessage}</label>
      </div>
    )
  }

  useEffect(() => {
    if (props.isOpen && props.Api) {
      props.Api.getCompanyById(props.popupData.id)
        .then((res) => {
          props.setPopupData(res);
          setValues(res);
        })
        .catch((err) => {
          console.error('Ошибка при загрузке данных заявки:', err);
        })
    }
  }, [props.isOpen, props.Api]);

  return (
    <div className={`popup ${props.isOpen ? 'popup_them_open' : ''}`}>
      <div className='popup__container'>
        <div className='popup__tittle_wrap'>
          <h5 className='popup__tittle'>{`Информация по организации №${props.popupData.id} - ${props.popupData.name}`}</h5>
          <button className='popup__close_button' onClick={handleCloseButton}></button>
        </div>

        <div className='popup__form' noValidate>
          <div className='popup__wrapp'>
            <ul className='popup__nav'>
              <li className='popup__nav-item popup__nav-item_theme_select' id={1} onClick={handleNavClick}>Данные организации</li>
            </ul>
            <div className='popup__data'>
              <form className='profile__content' onSubmit={handleSubmit} noValidate>
                <fieldset className='profile__item'>
                  {defaultInput('Организация', 'name', 'Название организации', true, 'Поле Организация имеет ошибочный формат')}
                  {defaultInput('Адрес', 'address', 'Введите адрес', true, 'Поле Адрес имеет ошибочный формат')}
                </fieldset>

                <fieldset className='profile__item'>
                  {defaultInput('График работы', 'schedule', '9:00-18:00 Пн-Пт', undefined, 'Поле график имеет ошибочный формат')}
                  {defaultInput('Контактный номер', 'phone', '+7(812)123-45-67', true)}
                </fieldset>

                <fieldset className='profile__item'>
                  {defaultInput('Контактный email', 'email_contact', 'email@email.ru', true, undefined, undefined, undefined, 'email')}
                  {defaultInput('Координаты яндекс', 'coords', '59.943988, 30.306329', true)}
                </fieldset>
                <fieldset className='profile__item'>
                  {defaultInput('Ссылка на прайс', 'price', 'price.ru')}
                </fieldset>
                <button id='submit' className='profile__submit' type='submit' disabled={!isValid}>Сохранить</button>
              </form>
            </div>
          </div>

          <div className='popup__submit_wrap'>
            <button type='button' className='popup__submit' onClick={handleCloseButton}>Ок</button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ContainerPopup;