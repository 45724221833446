import { createContext } from "react";

export const CurrentUser = {
  id: null,
  login: null,
  email: '',
  verified_email: false,
  role: 0,
  profile: {
    surname: '',
    name: '',
    patronymic: '',

    phone: '',
    email_contact: '',

    accept: false
  }
};

export const CurrentUserContext = createContext(CurrentUser);