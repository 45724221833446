import React, { useContext } from 'react';
import logo from '../images/yashaLogo.jpg';
import { CurrentUserContext } from '../contexts/CurrentUserContext';

function Header(props) {
  const currentUser = useContext(CurrentUserContext);

  return (
    <header className='header'>
      <div className='header__left-container'>
        <img src={logo} alt='Logotype' />
        <button type='button' className='header__toggler' onClick={props.handleSidebarClick}></button>
      </div>
      <div className='header__right-container'>
        <span className='header__right-item'>{currentUser.login}</span>
        <a target="_self" onClick={props.onSignOut}>Выход</a>
      </div>
    </header>
  );
};

export default Header;