import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFormWithValidation from '../../utils/Validator';
import LoaderHor from '../../utils/LoaderHor';

function Register({ onReg, ...props }) {
  const [status, setStatus] = useState(false);
  const [err, setErr] = useState('');

  const { values, handleChange, errors, isValid, resetForm } = useFormWithValidation('form__input_theme_invalid');
  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    setStatus(true);

    onReg(values.loginReg, values.emailReg, values.passwordReg)
      .then((res) => {
        navigate('/signin');
        resetForm();
      })
      .catch((err) => {
        setErr(err.message);
      })
      .finally(() => {
        setStatus(false);
      });
  }

  function defaultInput(id, type = 'text', placeholder, isRequired = false, validMessage = '', minLength = 2, maxLength = 20) {
    return <><input className='form__input' type={type} id={id} name={id} autoComplete='off' required={isRequired}
      placeholder={placeholder} minLength={minLength} maxLength={maxLength} value={values[id] || ''} onChange={handleChange} />
      {document.getElementById(id) != null && document.getElementById(id).classList.contains('form__input_theme_invalid') ?
        <label className='form__error' htmlFor={id}>{errors[id] ? errors[id] : validMessage}</label> : <></>}</>
  }

  return (
    <form className='form' onSubmit={handleSubmit} noValidate>
      {defaultInput('loginReg', 'text', 'Введите логин', true, 'Логин должен состоять из букв и цифр без пробелов и спецсимволов', 3)}
      {defaultInput('emailReg', 'email', 'Введите email', true, 'Email должен соответствовать формату username@domain.com', 6, 254)}
      {defaultInput('passwordReg', 'password', 'Введите пароль', true, 'Пароль должен быть от 8 до 16 символов. Содержать хотя бы одну строчную букву, заглавную букву, цифру и спецсимвол', 8, 16)}
      {defaultInput('passwordReg_1', 'password', 'Введите подтверждение пароля', true, 'Пароли не совпадают', 8, 16)}

      {err ? <label className='form__error' >{err}</label> : <></>}
      <button className='form__confirm-button' type='submit' disabled={!isValid}>{status ? <LoaderHor /> : 'Зарегистрироваться'}</button>

      <div className='form__buttons'>
        <button className='form__button' onClick={() => navigate('/recovery')} type='button'>Забыли пароль?</button>
        <button className='form__button' onClick={() => navigate('/signin')} type='button'>Вход</button>
      </div>
    </form>
  );
};

export default Register;