import React, { useState, useEffect } from 'react';
import Table from '../utils/Table';
import ContainerPopup from './ContainerPopup';
import RequestPopup from './CreatePopup';
import Pagination from '../utils/Pagination';
import { useRole } from '../contexts/RoleContext';

function Admin(props) {
  const { role } = useRole();
  const [data, setData] = useState([]);
  const [containerPopupIsOpen, setContainerPopupIsOpen] = useState(false);
  const [containerPopupData, setContainerPopupData] = useState({});
  const [createPopupIsOpen, setCreatePopupIsOpen] = useState(false);
  const [resStatus, setResStatus] = useState(false);
  const [pagination, setPagination] = useState([]);

  if (role !== 'admin')
    return null;


  const headers = [
    {
      header: 'Ид'
    },
    {
      header: 'Название'
    },
    {
      header: 'email'
    },
    {
      header: 'Телефон'
    },
    {
      header: 'Расписание'
    },
    {
      header: 'Координаты'
    },
    {
      header: 'Адрес'
    },
    {
      header: 'Данные организации'
    },
  ]

  function getData(api, param = { offset: 0 }) {
    setResStatus(true);

    api.getAllCompany(param).then(res => {
      setData(res.data.sort((a, b) => a.id - b.id));
      setPagination({ totalPages: res.totalPages, currentPage: res.currentPage });
    })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setResStatus(false);
      });
  }

  function fileButtonHandler(id) {
    setContainerPopupIsOpen(true)
    setContainerPopupData({ id: id });
  }

  function refreshHandler() {
    getData(props.Api);
  }

  function template(row, rowIndex) {
    return <>
      <td>{row.id}</td>
      <td>{row.name}</td>
      <td>{row.email}</td>
      <td>{row.phone}</td>
      <td>{row.schedule}</td>
      <td>{row.coords}</td>
      <td>{row.address}</td>
      <td><button id={rowIndex} className='table__container-button table__container-button_theme_small'
        onClick={() => fileButtonHandler(row.id)}><i className='table__icon-info'></i></button></td>
    </>
  };

  function handlePageChange(page) {
    getData(props.Api, { offset: 10 * (page - 1) });
  }

  useEffect(() => {
    if (props.Api)
      getData(props.Api);
  }, [props.Api]);

  return (
    <div className='content'>
      <div className='certificate'>
        <fieldset className='certificate__buttons'>
          <button className='certificate__button' name='newRequest' onClick={() => setCreatePopupIsOpen(true)}>Создать организацию</button>
          <button className='certificate__button' onClick={refreshHandler}>Обновить таблицу</button>
        </fieldset>
        {resStatus ? <span className='loader' /> : <Table headers={headers} data={data} template={template} setContPopup={props.setContPopup} />}

        <RequestPopup Api={props.Api} isOpen={createPopupIsOpen} popupHandler={setCreatePopupIsOpen} refreshHandler={refreshHandler} />

        <ContainerPopup Api={props.Api} isOpen={containerPopupIsOpen} popupHandler={setContainerPopupIsOpen} popupData={containerPopupData}
          setPopupData={setContainerPopupData} refreshHandler={refreshHandler} />

        <Pagination totalPages={pagination.totalPages} currentPage={pagination.currentPage} onPageChange={handlePageChange}></Pagination>
      </div>
    </div>
  );
};

export default Admin;