import React from 'react';
import useFormWithValidation from '../utils/Validator';

function CreatePopup({ component: Component, ...props }) {
  const { values, handleChange, isValid, resetForm } = useFormWithValidation('profile__input_theme_invalid', 'profile__input_theme_valid');

  function handleCloseButton() {
    props.popupHandler(!props.isOpen);
    resetForm();
  }

  function handleSubmit(e) {
    e.preventDefault();
    console.log(values);
    props.Api.createCompany(values);

    props.refreshHandler();
    handleCloseButton();
  }

  function defaultInput(text, id, placeholder, isRequired = false, validMessage = '', minLength = 2, maxLength = 20, type = 'text',) {
    return (
      <div className='profile__input-box'>
        <label className='profile__label' htmlFor={id}>{text}</label>
        <input className='profile__input' type={type} id={id} name={id} placeholder={placeholder} minLength={minLength} maxLength={maxLength}
          value={values[id] || ''} onChange={handleChange} required={isRequired} />
        <label className='profile__label_valid' htmlFor={id}>{validMessage}</label>
      </div>
    )
  }

  return (
    <div className={`popup ${props.isOpen ? 'popup_them_open' : ''}`}>
      <div className='popup__container'>
        <div className='popup__tittle_wrap'>
          <h5 className='popup__tittle'>Создание новой организации</h5>
          <button className='popup__close_button' onClick={handleCloseButton}></button>
        </div>
        <form className='profile__content' onSubmit={handleSubmit} noValidate>
          <fieldset className='profile__item'>
            {defaultInput('Организация', 'name', 'Название организации', true, 'Поле Организация имеет ошибочный формат')}
            {defaultInput('Адрес', 'address', 'Введите адрес', true, 'Поле Адрес имеет ошибочный формат', 2, 120)}
          </fieldset>

          <fieldset className='profile__item'>
            {defaultInput('График работы', 'schedule', '9:00-18:00 Пн-Пт', undefined, 'Поле график имеет ошибочный формат')}
            {defaultInput('Контактный номер', 'phone', '+7(812)123-45-67', true)}
          </fieldset>

          <fieldset className='profile__item'>
            {defaultInput('Контактный email', 'email', 'email@email.ru', true, undefined, undefined, undefined, 'email')}
            {defaultInput('Координаты яндекс', 'coords', '59.943988, 30.306329', true)}
          </fieldset>
          <fieldset className='profile__item'>
            {defaultInput('Ссылка на прайс', 'price', 'price.ru')}
          </fieldset>
          <button id='submit' className='profile__submit' type='submit' disabled={!isValid}>Сохранить</button>
        </form>
      </div>
    </div>
  )
}

export default CreatePopup;