import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import useFormWithValidation from '../../utils/Validator';
import LoaderHor from '../../utils/LoaderHor';

function Login({ onLog, ...props }) {
  const [status, setStatus] = useState(false);
  const [err, setErr] = useState('');
  const { values, handleChange, isValid, resetForm } = useFormWithValidation('form__input_theme_invalid');
  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    setStatus(true);

    onLog(values.login, values.password)
      .then(() => {
        navigate('/request');
        resetForm();
      })
      .catch((err) => {
        setErr(err.message);
      })
      .finally(() => {
        setStatus(false);
      });
  };

  return (
    <form className='form' onSubmit={handleSubmit} noValidate>
      <input className='form__input' type='text' name='login' id='login' autoComplete='on'
        placeholder="Введите имя пользователя (логин)" minLength="3" maxLength="20" required
        value={values.login ? values.login : ''} onChange={handleChange} />

      <input className='form__input' type='password' name='password' id='password' autoComplete='on'
        placeholder='Введите пароль' minLength='8' maxLength='16' required
        value={values.password ? values.password : ''} onChange={handleChange} />
      {err ? <label className='form__error' >{err}</label> : <></>}

      <button className='form__confirm-button' type='submit' disabled={!isValid}>{status ? <LoaderHor /> : 'Войти'}</button>
      <div className='form__buttons'>
        <button className='form__button' onClick={() => navigate('/recovery')} type='button'>Забыли пароль?</button>
        <button className='form__button' onClick={() => navigate('/signup')} type='button'>Регистрация</button>
      </div>
    </form>
  );
};

export default Login;