import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useRole } from '../contexts/RoleContext';

function Sidebar(props) {
  const location = useLocation();
  const { role } = useRole();
  const [links, setLinks] = useState([
    ['/profile', 'Профиль', 'nav__user'],
    ['/request', 'Заявки', 'nav__badge']
  ]);

  useEffect(() => {
    if (role === 'admin')
      setLinks(links => [
        ...links,
        ['/admin', 'Администрирование', 'nav__badge']
      ]);

    if (role === 'moder') {
      const newElement = ['/company', 'Организация', 'nav__company'];
      const updatedLinks = [...links];
      updatedLinks.splice(1, 0, newElement);
      setLinks(updatedLinks);
    }

  }, [role]);

  return (
    <div className={`${props.isOpen ? 'sidebar sidebar_open' : 'sidebar'} ${props.isMini ? 'sidebar_mini' : ''}`}>
      <nav className='nav'>
        {
          links instanceof Array ?
            links.map((link, index) => (
              <Link key={index} to={link[0]} className={location.pathname === link[0] ? 'nav__link nav__link_active' : 'nav__link'}><i className={`${location.pathname === link[0] ? `nav__icon ${link[2]} nav__icon_active` : `nav__icon ${link[2]}`}`
                + ` ${props.isMini ? 'nav__icon_min' : ''}`}> </i>{props.isMini ? '' : link[1]}</Link>
            )) : <></>
        }
      </nav>
      <button type="button" className={'sidebar__minimizer' + `${props.isMini ? ' sidebar__minimizer_rotate' : ''}`} onClick={props.handleMiniClick} />
    </div>
  );
};

export default Sidebar;
