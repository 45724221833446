import React, { createContext, useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from './CurrentUserContext';

const RoleContext = createContext();

export const RoleProvider = ({ children }) => {
  const [role, setRole] = useState(null);
  const user = useContext(CurrentUserContext);

  useEffect(() => {
    if (user.role)
      setRole(user.role);
  }, [user.role]);

  return (
    <RoleContext.Provider value={{ role, setRole }}>
      {children}
    </RoleContext.Provider>
  );
};

export const useRole = () => useContext(RoleContext);
