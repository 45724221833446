import React from 'react';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const getPaginationRange = () => {
    const range = [];
    const start = Math.max(1, currentPage - 2); // Две страницы до текущей
    const end = Math.min(totalPages, currentPage + 2); // Две страницы после текущей

    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  };

  const paginationRange = getPaginationRange();

  return (
    <div className="pagination">
      {/* Кнопка "Предыдущая страница" */}
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        &laquo;
      </button>

      {/* Кнопка первой страницы, если текущая страница далеко от начала */}
      {paginationRange[0] > 1 && (
        <>
          <button onClick={() => onPageChange(1)}>1</button>
          {paginationRange[0] > 2 && <span>...</span>}
        </>
      )}

      {/* Центральный диапазон страниц */}
      {paginationRange.map((page) => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          className={page === currentPage ? 'active' : ''}
        >
          {page}
        </button>
      ))}

      {/* Кнопка последней страницы, если текущая страница далеко от конца */}
      {paginationRange[paginationRange.length - 1] < totalPages && (
        <>
          {paginationRange[paginationRange.length - 1] < totalPages - 1 && (
            <span>...</span>
          )}
          <button onClick={() => onPageChange(totalPages)}>{totalPages}</button>
        </>
      )}

      {/* Кнопка "Следующая страница" */}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        &raquo;
      </button>
    </div>
  );
};

export default Pagination;
